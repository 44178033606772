import './hp.css';
import { scrollToElement } from '../scroll/scroll';


(function($){
    const elRegisterBtns = document.querySelectorAll('.btn-register');
    const elCta = document.querySelector('.cta')
    const elAboutSection = document.querySelector('.s2')
    const elFormContainer = document.querySelector('.form-container');
    let carousel_section = $('.section.s8');
    let lightSliderLoaded = false;
    let counter = $('.counter');
    let counterloaded = false;
    let counterArray = [];
    const main_header = document.querySelector('.main-header');
    const cookiNotif = document.getElementById('cookie-notif');

    for (var i =0; i< counter.length; i++){
        counterArray.push(counter[i]);
    }

    elRegisterBtns.forEach(elRegisterBtn =>{
        elRegisterBtn.addEventListener('click',() => {
            const formYCoord = elFormContainer.getBoundingClientRect().top - document.body.getBoundingClientRect().top - (main_header.clientHeight - cookiNotif.clientHeight);
            scrollToElement(formYCoord ,500);
        })
    
    })

    elCta.addEventListener('click',() => {
        const formYCoord = elAboutSection.getBoundingClientRect().top - document.body.getBoundingClientRect().top - (main_header.clientHeight - cookiNotif.clientHeight);
        scrollToElement(formYCoord ,500);
    })

    function doScrolling() {
        
        if (carousel_section.length > 0 && !lightSliderLoaded &&  typeof window.isInViewport != 'undefined' && window.isInViewport(carousel_section)) {
            import(/* webpackChunkName: "lightSlider" */ '../lightSlider/lightSlider');
            lightSliderLoaded = true;
        }

        if(!counterloaded && counter.length > 0 && window.isInViewport(counter) ){
            counterloaded = true;
            counterArray.forEach(function (item) {
        
                $(item).prop('counter',0).animate({
                 Counter: $(item).attr('data-start')
             }, {
                 duration: 1700,
                 easing: 'swing',
                 step: function (now) {
                    $(item).text(Math.ceil(now));
                 }
                 
             });
             setTimeout(function(){ $(item).text($(item).attr('data-num')); }, 1800);
            
            
            
            });
        }
    
    }
    window.scrollingTasks.push(doScrolling);

})(window.jQuery);

